import { DataStatus } from '../types/enum/data-status.enum';
import { WidgetProps } from 'components/Widget/Widget';
import { PatientData } from 'types/patient';
import { getBatteryLevelStatus } from 'utils/get-battery-level-status';
import { getDeviceConfigStatus } from 'utils/get-device-config-status';
import { getBloodOxygenStatus } from './get-blood-oxygen-status';
import { getFallDetectionStatus } from './get-fall-detection-status';
import { getHeartRateStatus } from './get-heart-rate-status';
import { getZoneStatus } from './get-zone-status';
import { getHeartRhythmStatus } from './get-heart-rhythm-status';
import { getHelpRequestStatus } from './get-help-request-status';

import { sleepTime } from 'assets/iconify/sleep-time';
import { stepCount as stepCountIconify } from 'assets/iconify/step-count';
import { vo2Max as vo2Iconify } from 'assets/iconify/vo2-max';
import { energyBurn as energyBurnIconify } from 'assets/iconify/energy-burn';
import { routes } from 'nav';
import { palette } from 'styles/palette';
import { postgresAgeToMin } from './postgres-age-to-min';
import { getSeizureDetectedData } from './get-seizure-detected-data';

type Timestamp = string | number;
const extractTimestamps = (object: { lastUpdate?: Timestamp }) => ({
  lastUpdate: object.lastUpdate,
});

export const getDashboardWidgets = (
  data?: PatientData | null,
  options?: {
    hasSeizureDetection?: boolean;
    isHealthDataCollected?: boolean;
    isLocationDataCollected?: boolean;
  },
): WidgetProps[] => {
  if (data) {
    const widgets: WidgetProps[] = [];

    const {
      accountId,
      batteryLevel,
      bloodOxygen,
      device,
      fallAlert,
      heartRate,
      safeZone,
      stepCount,
      dailySleep,
      heartRhythm,
      energyBurn,
      helpRequest,
      seizureDetected,
      vO2,
    } = data;
    if (device) {
      widgets.push({
        ...getDeviceConfigStatus(
          !!device.isConfigOk,
          device.wornStatus,
          device.isActive,
          device.isLocked,
        ),
        ...extractTimestamps(device),
        title: 'Device',
        widgetType: 'device',
        href: routes.private.dashboardData.href(accountId, 'device'),
        hasAlert: true,
      });
    }

    if (batteryLevel) {
      widgets.push({
        ...getBatteryLevelStatus(batteryLevel?.value, batteryLevel.isCharging),
        title: 'Battery',
        widgetType: 'battery',
        ...extractTimestamps(batteryLevel),
        href: routes.private.dashboardData.href(accountId, 'battery'),
        hasAlert: batteryLevel.hasAlert,
      });
    }

    if (heartRate) {
      if (options?.isHealthDataCollected) {
        widgets.push({
          ...getHeartRateStatus(heartRate.status, heartRate?.value ?? 0),
          title: 'Heart Rate',
          widgetType: 'heart_rate',
          unit: 'BPM',
          ...extractTimestamps(heartRate),
          href: routes.private.dashboardData.href(accountId, 'heartRate'),
          hasAlert: heartRate.hasAlert,
        });
      } else {
        widgets.push({
          ...getHeartRateStatus(undefined),
          title: 'Heart Rate',
          widgetType: 'heart_rate',
          unit: 'BPM',
          disabledCategory: 'health',
        });
      }
    }

    if (heartRhythm) {
      if (options?.isHealthDataCollected) {
        widgets.push({
          ...getHeartRhythmStatus(
            heartRhythm.value,
            heartRhythm.isIrregularNow,
          ),
          title: 'Heart Rhythm',
          widgetType: 'heart_rhythm',
          ...extractTimestamps(heartRhythm),
          href: routes.private.dashboardData.href(accountId, 'heartRhythm'),
          hasAlert: heartRhythm.hasAlert,
        });
      } else {
        widgets.push({
          ...getHeartRhythmStatus(undefined, undefined, true),
          title: 'Heart Rhythm',
          widgetType: 'heart_rhythm',
          disabledCategory: 'health',
        });
      }
    }

    if (safeZone) {
      if (options?.isLocationDataCollected) {
        widgets.push({
          ...getZoneStatus(safeZone.isInSafeZone),
          title: 'Safe Zone',
          widgetType: 'safe_zone',
          ...extractTimestamps(safeZone),
          href: routes.private.dashboardData.href(accountId, 'safeZone'),
          hasAlert: safeZone.hasAlert,
        });
      } else {
        widgets.push({
          ...getZoneStatus(undefined),
          title: 'Safe Zone',
          widgetType: 'safe_zone',
          disabledCategory: 'location',
        });
      }
    }

    if (fallAlert) {
      widgets.push({
        ...getFallDetectionStatus(fallAlert.hasUnreviewdFall),
        title: 'Fall Detection',
        widgetType: 'fall',
        ...extractTimestamps(fallAlert),
        href: routes.private.dashboardData.href(accountId, 'fallDetection'),
        hasAlert: fallAlert.hasAlert,
      });
    }

    if (bloodOxygen) {
      if (options?.isHealthDataCollected) {
        widgets.push({
          ...getBloodOxygenStatus(bloodOxygen.value),
          title: 'Blood Oxygen',
          widgetType: 'blood_oxygen',
          ...extractTimestamps(bloodOxygen),
          href: routes.private.dashboardData.href(accountId, 'bloodOxygen'),
          hasAlert: bloodOxygen.hasAlert,
        });
      } else {
        widgets.push({
          ...getBloodOxygenStatus(undefined),
          title: 'Blood Oxygen',
          widgetType: 'blood_oxygen',
          disabledCategory: 'health',
        });
      }
    }

    if (dailySleep && dailySleep.value) {
      widgets.push({
        value: postgresAgeToMin(dailySleep.value ?? ''),
        title: 'Sleep Time',
        widgetType: 'daily_sleep_time',
        unit: 'MIN',
        status: DataStatus.POSITIVE,
        icon: sleepTime.icons.normal,
        href: routes.private.dashboardData.href(accountId, 'sleepTime'),
        //goal: 460,
        ...extractTimestamps(dailySleep),
        hasAlert: dailySleep.hasAlert,
      });
    }

    widgets.push({
      ...getHelpRequestStatus(helpRequest?.requestedHelp),
      title: 'Help Request',
      widgetType: 'help_requested',
      hasAlert: helpRequest?.hasAlert,
      lastUpdate: helpRequest?.lastUpdate,
    });

    if (stepCount) {
      if (options?.isHealthDataCollected) {
        widgets.push({
          title: 'Daily Step Count',
          status: DataStatus.POSITIVE,
          icon: stepCountIconify.icons.normal,
          widgetType: 'daily_step_count',
          value: stepCount.value ?? 'N/A',
          // label: 'Achieved',
          ...extractTimestamps(stepCount),
          hasAlert: stepCount.hasAlert,
        });
      } else {
        widgets.push({
          title: 'Daily Step Count',
          icon: stepCountIconify.icons.normal,
          status: DataStatus.DISABLED,
          value: 'N/A',
          disabledCategory: 'health',
        });
      }
    }

    if (options?.hasSeizureDetection) {
      widgets.push({
        ...getSeizureDetectedData(seizureDetected?.detected),
        title: 'Seizure Detection',
        widgetType: 'motion_disorder',
        hasAlert: seizureDetected?.hasAlert,
        lastUpdate: seizureDetected?.lastUpdate,
      });
    }

    if (energyBurn) {
      if (options?.isHealthDataCollected) {
        widgets.push({
          ...extractTimestamps(energyBurn),
          title: 'Daily Energy Burn',
          icon: energyBurnIconify.icons.normal,
          value: energyBurn.value ? Math.round(energyBurn.value) : 'N/A',
          unit: 'CAL',
          widgetType: 'daily_calorie_burn',
          status: energyBurn.value ? DataStatus.POSITIVE : DataStatus.DISABLED,
          hasAlert: energyBurn.hasAlert,
        });
      } else {
        widgets.push({
          title: 'Daily Energy Burn',
          icon: energyBurnIconify.icons.normal,
          unit: 'CAL',
          status: DataStatus.DISABLED,
          value: 'N/A',
          disabledCategory: 'health',
        });
      }
    }

    if (vO2) {
      if (options?.isHealthDataCollected) {
        widgets.push({
          title: 'Cardio Fitness',
          value: vO2.value ? vO2.value.toFixed(1) : 'N/A',
          iconColor: palette.primary.main,
          icon: vo2Iconify.icons.normal,
          unit: 'ml/kg/min',
          ...extractTimestamps(vO2),
        });
      } else {
        widgets.push({
          title: 'Cardio Fitness',
          icon: vo2Iconify.icons.normal,
          unit: 'ml/kg/min',
          status: DataStatus.DISABLED,
          value: 'N/A',
          disabledCategory: 'health',
        });
      }
    }
    return widgets;
  }

  return [];
};
